import { createWebHistory, createRouter } from "vue-router";
import store from "../store";
import AppLayout from "@/views/layout/AppLayout.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/login/Login.vue"),
      meta: {
        title: "DataRete - Accesso",
      },
    },
    {
      path: "/changepasswordrequest",
      name: "ChangePasswordRequest",
      component: () => import("../views/login/ChangePasswordRequest.vue"),
      meta: {
        title: "DataRete - Richiesta Cambio Password",
      },
    },
    {
      path: "/changepasswordperform/:code",
      name: "ChangePasswordPerform",
      component: () => import("@/views/login/ChangePasswordPerform.vue"),
      meta: {
        title: "DataRete - Realizza Cambio Password",
      },
    },
    {
      path: "/setloginlevel",
      name: "SetLoginLevel",
      component: () => import("../views/login/SetLoginLevel.vue"),
      meta: {
        title: "DataRete - Seleaziona il livello di Accesso",
      },
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("../views/PerformLogout.vue"),
    },
    {
      path: "/conferma-pagamento",
      name: "ConfermaPagamento",
      component: () => import("../views/ConfermaPagamento.vue"),
    },
    {
      path: "/errore-pagamento",
      name: "ErrorePagamento",
      component: () => import("../views/ErrorePagamento.vue"),
    },
    {
      path: "/link/:externalLink",
      name: "Link",
      component: () => import("../views/Link.vue"),
    },
    {
      path: "/",
      component: AppLayout,
      children: [
        {
          path: "",
          name: "Bacheca",
          component: () => import("@/views/Bacheca.vue"),
        },
        {
          path: "profilo",
          name: "ProfiloUtente",
          component: () => import("@/views/ProfiloUtente.vue"),
        },
        {
          path: "/testtry1",
          name: "test",
          component: () => import("@/views/TestTry.vue"),
        },
        {
          path: "/testtry3",
          name: "test3",
          component: () => import("@/views/Testtry3.vue"),
        },
        {
          path: "gestione",
          name: "gestione",
          children: [
            {
              path: "utenti",
              component: () => import("@/views/gestione/Users.vue")
            },
            {
              path: "notizie",
              component: () => import("@/views/gestione/Notizie.vue"),
              meta: {
                requiresAdmin: true,
              },
            },
          ],
        },
        {
          path: "plugin",
          name: "plugins",
          children: [
            {
              path: "data-drive",
              name: "DataDrive",
              component: () => import("@/views/plugins/DataDrive.vue"),
            },
          ],
        },
        {
          path: "agenda",
          name: "Agenda",
          children: [
            {
              path: "calendario",
              name: "Calendario",
              component: () => import("@/views/agenda/Calendario.vue"),
              meta: {
                requiresAdmin: true,
              },
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  let livelloLogin = "";

  if (store.loggedUser) {
    livelloLogin = store.getters.loggedUser.livelloLogin;
  }

  const isAuthenticated = store.getters.isAuthenticated;

  if (
    !isAuthenticated &&
    to.name !== "Login" &&
    to.name !== "ChangePasswordRequest" &&
    to.name !== "ChangePasswordPerform"
  ) {
    next({ name: "Login" });
  } else if (
    to.matched.some((record) => record.meta.requiresAdmin) &&
    livelloLogin !== "Amministratore"
  ) {
    next({ name: "Bacheca" });
  } else {
    next();
  }
});

export default router;
